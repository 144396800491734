import V from 'voUtils/V.js';
import DateEx from 'voUtils/DateEx.js';

export default {
	voVueComponent: 'panorama',
	data: function () {
		return {};
	},
	props: {
		panolist: Array
	},
	mounted: function () {
		//console.log('pano updated....' + this.panolist);
		var panos = {};
		var maxSize;
		try {
			var canvas = document.createElement('canvas');
			var gl = canvas.getContext('experimental-webgl');
			maxSize = gl.getParameter(gl.MAX_TEXTURE_SIZE);
		} catch (e) {
			console.log("could not detect maxSize");
		}
		//var size = (maxSize && maxSize <= 4096) ? 'gl' : 'pano';
		var size = 'pano';

		for (let pano of this.panolist) {
			//console.log('pano.id::' + pano.id);
			pano.type = "equirectangular";
			pano.panorama = 'https://dyn.v-office.com/image/' + size + '/' + pano.id + '.jpg';
			pano.title = pano.title;
			/*console.log('pano.panorama::' + pano.panorama);
			console.log('pano.hfov:' + pano.hfov);*/

			if (!pano.hfov) {
				pano.hfov = 120;
			}
			if (pano.hotSpots) {
				for (let hs of pano.hotSpots) {
					//console.log('hs::' + JSON.stringify(hs));
					var linkedPanorama = hs.linkedPanorama;
					if (linkedPanorama) {
						hs.sceneId = 'p' + linkedPanorama;
					}
					if (!hs.text) {
						hs.text = 'Hotspot';
					}
					if (hs.pitch) {
						hs.pitch = hs.pitch / 1000;
					}
					if (hs.yaw) {
						hs.yaw = hs.yaw / 1000;
					}
					if (hs.linkedPanorama) {
						delete hs.linkedPanorama;
					}
				}
			}
			//console.log('pano:' + pano);
			panos['p' + pano.id] = pano;

		}
		pannellum.viewer('panorama', {
			"default": {
				"firstScene": 'p' + this.panolist[0].id,
				"autoLoad": true,
				"sceneFadeDuration": 1000,
				"autoRotate": -2
			},
			//"hotSpotDebug": true,
			"scenes": panos
		});
	}
};